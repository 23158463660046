import birds from "./birds.png";
import scraps from "./scraps.png";
import softwax from "./softwax.png";
import aboutSquare from "./about_square.png";
import aboutRect from "./about_rect.png";

export const whatsNewSections = [
  {
    orientation: "left",
    title: "On the blog",
    text: `
There’s always going to be frustrating pieces that make you want to quit. 

Don’t! It’s going to be worth the struggle in the end.  In the newest blog post you can read why I think you should keep going and don’t give up!

  `,
    link: `/blog/post/do-not-give-up`,
    linktext: `Read why you shouldn’t give up`,
    image: birds,
    imagealt: `Close up of bench with 3 partially finished bird pieces`,
  },

  {
    orientation: "right",
    title: `Behind the scenes`,
    text: `
Sorting scraps after finishing a class. 

Where do the pieces with different waxes melted together go?

`,
    image: scraps,
    imagealt:
      "Table with 2 plastic trays, one filled with blue wire wax and one with pink sheet wax. In the middle scrap pieces of wax that have both and need to be sorted still",
  },

  {
    orientation: "left",
    title: `With the classes`,
    text: `
It’s finally here!

In the soft wax bezel setting class, you learn how to make bezels for both cabochon/rosecut stones and faceted stones from different soft waxes.

You learn all about how to form the wax around your stone to create bezels, and how to finish the settings to your liking. 
`,
    link: `class/soft-wax-bezel-settings`,
    linktext: `Show me the soft wax bezel class!`,
    image: softwax,
    imagealt:
      "A collection of 13 different settings pieces on a white background. The pieces are made from pink sheet wax, blue wire wax, and red wax.",
  },
];

export const blurbsection = {
  text: `Wax carving is a fun jewellery making technique where you transform an unassuming piece of wax into a beautiful piece of jewellery! Learn to carve rings,  3-dimensional shapes, classic pieces, and bold statement jewellery. 

Anything is possible in wax!

Through video tutorials you learn to carve your own piece of jewellery ready to be cast in the metal of your choice. Want to know what you can make?`,
  linktext: `Yes! Show me the classes!`,
  link: `/classes`,
};

export const aboutsection = {
  title: `Hi I'm Sandy!`,
  text: `I’m the CEO (Chief EVERYTHING Officer) here at Wax Carvers.
  
I did my first jewellery making class in 2012 and haven’t looked back since. I fell in love with wax carving in 2018 and I’m excited to share that love with you!
`,
  linktext: `Want to know more?`,
  link: `/about`,
  squarephoto: aboutSquare,
  rectphoto: aboutRect,
};

export const blogsection = {
  text: `
Do you want to know what tools you need to get started with wax carving?

Do you want to know what wax color you should get?

Do you want to know how you can set up a quick bench when you don’t have space for a dedicated one?

All these topics and more are covered on the blog! A new post is published every other Tuesday. So you can keep coming back to learn more about wax carving.
`,
  linktext: `Let's read that blog!`,
  link: `/blog`,
};
